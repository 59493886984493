import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { PATH_APP } from '~/routes/paths';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '~/components/LoadingScreen';
import { isEmpty } from 'react-redux-firebase';
import { isLoaded, isAuthenticated, checkToken } from '~/redux/slices/auth';
import store from '~/redux/store'

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);

  if (auth.isLoading) {
    return <LoadingScreen />;
  }

  if (auth.isAuthenticated) {
    return <Redirect to={PATH_APP.root} />;
  }

  return children;
}

export default GuestProtect;

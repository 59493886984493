import React from 'react';
import { MLabel, MIcon } from '~/@material-extend';
import SportsEsports from '@material-ui/icons/SportsEsports'
import { PATH_APP, PATH_PAGE } from '~/routes/paths';

// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
  home: <MIcon src={path('ic_stock')}/>,
  controller: <SportsEsports/>,
};

export default [
  // MAIN DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: 'Home',
    items: [
      {
        title: 'Top Klips',
        icon: ICONS.home,
        href: PATH_APP.main.root,
      },
      {
        title: 'Communities',
        icon: ICONS.controller,
        href: PATH_APP.communities.root,
      }
    ]
  },

    // USER MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Account management',
  //   items: [
  //     {
  //       title: 'user',
  //       icon: ICONS.user,
  //       href: PATH_APP.management.user.root,
  //       items: [
  //         {
  //           title: 'profile',
  //           href: PATH_APP.user.profile
  //         },
  //         // {
  //         //   title: 'cards',
  //         //   href: PATH_APP.management.user.cards
  //         // },
  //         // {
  //         //   title: 'list',
  //         //   href: PATH_APP.management.user.list
  //         // },
  //         {
  //           title: 'account',
  //           href: PATH_APP.user.account
  //         }
  //       ]
  //     },
  //   ]
  // },
];

import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import darkModeReducer from './slices/dark-mode';
import userReducer from './slices/user';
import notificationsReducer from './slices/notifications';
import authReducer from './slices/auth'
import homeReducer from './slices/home'
import KliprUserReducer from './slices/kliprUser'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  version: 1,
  whitelist: ['theme']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  blacklist: ['isLoading', 'error', 'products', 'product', 'filters']
};

const rootReducer = combineReducers({
  theme: darkModeReducer,
  user: userReducer,
  notifications: notificationsReducer,
  auth: authReducer,
  home: homeReducer,
  kliprUser: KliprUserReducer,

});

export { rootPersistConfig, rootReducer };

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
  isLoading: false,
  errors: {},
  topKlips: [],
  topCommunities: [],
}

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.errors = action.payload;
    },

    loadKlips(state, action) {
      state.topKlips = action.payload.topKlips
      state.topCommunities = action.payload.topCommunities
      state.isLoading = false
    }
  }
});

export default slice.reducer;

export const {
    loadKlips,
    hasError
} = slice.actions

export function fetchKlips() {
    return async dispatch => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios
        .get("/api/klip/home")
        console.log(response.data)
        dispatch(slice.actions.loadKlips(response.data))
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
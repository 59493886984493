import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
  isLoading: false,
  error: false,
  isAuthenticated: false,
  username: '',
  profileImage: [],
  profileBannerImages: [],
  email: '',
  about: '',
  userInfo: {},
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      if(action.payload.response == null || action.payload.response.status == null || action.payload.response.status == 404 || action.payload.response.status == 401) {
        console.log("We are not AUTHENTICATED!")
        state.isAuthenticated = false
      }
    },

    // GET checkToken
    checkTokenSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.username = action.payload.userHandle;
      state.profileImages = action.payload.profileImages
      state.profileBannerImages = action.payload.profileBannerImages
      state.about = action.payload.about
      state.email = action.payload.email
      state.userInfo = action.payload
    },

    //Successfully Logged in , Registered User, or update Username
    isAuthorized(state, action) {
      state.isAuthenticated = true
      state.username = action.payload || ''
    },

    //Successfully Logged Out
    noLongerAuthorized(state) {
      state.isAuthenticated = false
    },
  }
});

export default slice.reducer;

export const {
  isAuthorized,
  noLongerAuthorized
} = slice.actions

export function checkToken() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/users/fetchWithToken');
      dispatch(slice.actions.checkTokenSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function isLoaded(state) {
    return !(state.isLoading)
}
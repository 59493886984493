import React, { useEffect } from 'react';
import ThemeConfig from './theme';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from '~/routes';
import ScrollToTop from '~/components/ScrollToTop';
import GoogleAnalytics from '~/components/GoogleAnalytics';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import NotistackProvider from '~/components/NotistackProvider';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { useDispatch } from 'react-redux';
import { checkToken } from '~/redux/slices/auth';
import config from "./config/config"
import axios from 'axios'
import jwtDecode from 'jwt-decode'


// ----------------------------------------------------------------------

const history = createBrowserHistory();

axios.defaults.baseURL =
  config.dbUrl;

  
const token = localStorage.token;

//If a token didn't exist, this would be undefined, so it would return false
if(token) {
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp * 1000 < Date.now()){
    localStorage.clear();
    //window.location.href = '/login'
    console.log("Failed Auth")
  } else {
    axios.defaults.headers.common['Authorization'] = token
    console.log("Passed Auth")
  }
}


function App() {


  //Init Auth state
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);
  console.log("Checking auth")

  return (
    <ThemeConfig>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NotistackProvider>
          <Router history={history}>
              <ScrollToTop />
              <GoogleAnalytics />
              {renderRoutes(routes)}
          </Router>
        </NotistackProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
  isLoading: false,
  error: false,
  klips: [],
  userHandle: '',
  isOwner: false,
  profileImages: [],
  about: '',
  profileBannerImages: []
}

const slice = createSlice({
  name: 'kliprUser',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfileSuccess(state, action) {
        state.isLoading = false
        state.userHandle = action.payload.userHandle
        state.klips = action.payload.klips
        state.isOwner = action.payload.isOwner
        state.profileImages = action.payload.profileImages
        state.about = action.payload.about
        state.profileBannerImages = action.payload.profileBannerImages
    }
  }
});

export default slice.reducer;

export const {
    getProfileSuccess
} = slice.actions

export function getProfile(username) {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    console.log("Fetching Profile")
    try {
      let response = await axios.get(`/api/users/with${username}`)
      console.log(response.data)
      dispatch(slice.actions.getProfileSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
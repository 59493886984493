import React, { Component } from 'react';
import { Button, Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    margin: 10,
  }
}));

function LoginRegister() {
  const classes = useStyles();
  const auth = useSelector(state => state.auth)

  return (
    <div>
      { auth.isAuthenticated ? <div /> : (
        <div>
        <Button
        variant="contained"
        to="/auth/login"
        component={RouterLink}
        className={classes.button}
      >
        Login
      </Button>
      <Button
        variant="contained"
        to="/auth/register"
        component={RouterLink}
        className={classes.button}
      >
        Register
      </Button>
      </div>
      )}
    </div>
  );
}

export default LoginRegister

import { PATH_APP, PATH_DOCS } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import ProfileProtect from '~/components/Auth/ProfileProtect';
import AuthOnlyProtect from '~/components/Auth/AuthOnlyProtect';
import KliprLayout from '~/layouts/KliprLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: '*',
  layout: KliprLayout,
  routes: [
    // Home Page
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: '/',
      component: lazy(() => import('src/views/general/KliprHome'))
    },

    // KLIPS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.klips.viewKlip,
      component: lazy(() => import('~/views/general/ViewKlip'))
    },
    {
      exact: true,
      path: PATH_APP.klips.upload,
      guard: AuthOnlyProtect,
      component: lazy(() => import('~/views/general/UploadKlip'))
    },
    {
      exact: true,
      path: PATH_APP.klips.edit,
      // guard: AuthOnlyProtect,
      component: lazy(() => import('~/views/general/EditKlip'))
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.user.profile,
      component: lazy(() => import('~/views/user/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.user.cards,
      component: lazy(() => import('~/views/user/UserCardsView'))
    },
    {
      exact: true,
      path: PATH_APP.user.list,
      component: lazy(() => import('~/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.user.account,
      guard: AuthOnlyProtect,
      component: lazy(() => import('~/views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.user.root,
      component: () => <Redirect to={PATH_APP.user.profile} />
    },

    // COMMUNITIES
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.communities.root,
      component: lazy(() => import('~/views/Communities/Home'))
    },
    {
      exact: true,
      path: PATH_APP.communities.communityPage,
      component: lazy(() => import('~/views/Communities/CommunityView'))
    },
    {
      exact: true,
      path: PATH_APP.search.root,
      component: lazy(() => import('~/views/general/Search'))
    },
    // {
    //   exact: true,
    //   path: PATH_APP.management.root,
    //   component: () => <Redirect to={PATH_APP.management.user.profile} />
    // },

    // DOCUMENTATION
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DOCS.legal.privacypolicy,
      component: lazy(() => import('~/views/general/Documents/Legal/PrivacyPolicy'))
    },
    {
      exact: true,
      path: PATH_DOCS.news.basic,
      component: lazy(() => import('~/views/general/Documents/News'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
